
body{
    background-color: #f2f2f2;
}

.headings{
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction:column;
 text-align: center;
 margin: 20px auto;
}

.headings h1{
    font-size: 34px;
    color: #000000;
    margin-bottom: 15px;
    position: relative;
}

.headings h1::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    display: block;
    margin: 0 auto;
    background-color: #e68040;
}

.headings p{
    font-size: 18px;
    color: #666;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 50px;
    margin-right: 50px;
}

.container{

    margin: 0 auto;
    padding: 10px 20px;
}

.about-contents{
    justify-content: center;
    align-items: center;
 flex-direction:column;
 text-align: center; 
 margin-left: 270px ;
 margin-top: -20px;


}
.about-contents p{
    font-size: 18px;
    line-height: 1.3;
    color: #666;
    margin-bottom: 1px;
}

.about-obj{
    margin-top: 50px;
  
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
 
}

.col {
    flex: 0 0 25%; /* Four columns in a row */
    padding: 15px;
    box-sizing: border-box;
}

.col img {
    width: 80px;
    height: auto;
    display: block;
    margin-bottom: 10px;
    margin-left: 100px;
}
p{
    text-align: center;
}

.Curriculum-content{
display: flex;
flex-direction: column;
}

.container-c{
 display: flex;
 justify-content: center;
}

/* CSS File (MainContent.css) */
.container {
    display: flex;
    flex-direction: row;
  }
  
  .text-container {
    padding-top: 65px;
    font-size: medium;
    width: 50%;
    padding:100px 30px;
    box-sizing: border-box;
  }
  .text-container p{
    text-align: start;
    line-height: 25px;
    margin-bottom: 20px;
  }
  
  .image-container {
    width: 35%;
    height: 380px;
    padding-top: 30px;
    padding-left: 90px;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .image-container img {
    width: 250px;
    height: auto;
    object-fit: cover;
  }

  .cbse-text
  {
    margin-top: 20px;
    margin-left: 90px;
  }
  .cbse-text h2{
    color: #e68040;
    
  }
  .cur-image{
    max-width: 800px;
  }
  
  .cur-main{
    margin: 50px;
  }

  .curr-content{
    margin-top: -50px;
    margin-left: 20px;
  }


@media screen and (max-width: 767px) {
  .curr-content{
    margin-top: 0px;
    margin-left: 0px;
  }
  .founder-text{
    align-self: center;
  }
}

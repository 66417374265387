/* Founder.css */
.secondcontainer {
    width: 100%;
    box-sizing: border-box;
  }
  
  .founders {
    display: flex;
  
    justify-content: space-around;
  }

  .add-cen{
    justify-content: center;
  }
  
  .founder-section {
    margin: 10px;
    text-align: center;
  }
  
  /* Add other styles for your Founder section as needed */
  
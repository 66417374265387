/* AboutUs.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.container-founder {
  display: flex;
  justify-content: center;
  margin-right: 0px;
}

body {
  background-color: #f2f2f2;
}

.history {
  background-color: #fdfdfd;

}

.head-padding{
  padding-top: -10px;
}

.head,
.founders {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -30px;
}

.head h2,
.head h3,
.founders h2 {
  color: #3769e0;
  margin-bottom: 5px;
}

.head-content,
.founder-content {
  max-width: 600px;
  margin: 0 auto;
}

.head-image img,
.founder-image img {
  width: 100%;
  max-height: 500px; /* Adjust the max-height as needed */
  object-fit: cover;
  transition: transform 0.5s ease;
}

.head-image:hover img,
.founder-image:hover img {
  transform: scale(1.05);
}

.head-name {
  font-size: 21px;
  text-align: center;
  margin-bottom: 20px;
  color: green;
  font-size: large;
}

.founder-text {
  text-align: start !important;
}

.container {
  width: 90%;
  margin: 0 auto; /* Center-align the container */
  padding: 10px 20px;
}

.heading {
  text-align: center;
  margin: 20px auto;
}

.heading h1 {
  font-size: 34px;
  color: #000;
  margin-bottom: 25px;
  position: relative;
}

.heading p {
  font-size: 18px;
  color: #666;
  margin-bottom: 35px;
}

.about,
.head,
.founders {
  padding: 20px;
  flex-direction: column;
}

.about-image,
.head-image,
.founder-image {
  margin-bottom: 20px;
}

.about-image img,
.head-image img,
.founder-image img {
  width: 100%;
  max-height: 400px; /* Adjust the max-height as needed */
  object-fit: cover;
  transition: transform 0.5s ease;
}

.about-image:hover img,
.head-image:hover img,
.founder-image:hover img {
  transform: scale(1.05);
}

.about-content p,
.head-content p,
.founder-content p {
  font-size: 18px;
  line-height: 1.3;
  color: #666;
}

@media screen and (min-width: 768px) {
  .about,
  .head,
  .founders {
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Center-align items in the container */
  }

  .about-image,
  .head-image,
  .founder-image {
    flex: 1;
    margin-right: 20px;
  }

  .about-content,
  .head-content,
  .founder-content {
    flex: 2;
  }
}

@media screen and (max-width: 767px) {
  .container-founder {
    margin-right: 20px; /* Adjust the right margin for better spacing */
  }

  .about,
  .head,
  .founders {
    text-align: center; /* Center-align content on smaller screens */
  }
}

.facilityHeading {
  text-align: center;
  font-size: 2rem;
  padding: 30px;
  color: #333;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #e68040;
  text-underline-offset: 10px;
}

.facility-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.facility-card {
  margin: 50px 70px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
  width: 450px;
}

.facility-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #d3d2cd;
  color: #333;
}

.imgcontainer {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.facility-card img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.facility-card img.active {
  opacity: 1;
  position: relative;
}

.facility-card h3 {
  margin: 10px 0;
  color: #333;
  font-size: 1.4rem;
}

.facility-card p {
  color: #666;
  font-size: 1rem;
}

.facilitybox {
  background-color: #ccc;
  padding-bottom: 20px;
}

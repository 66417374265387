
  .about-content-h p {
    align-items: center;
    color: #666; /* Set paragraph text color as needed */
    margin-bottom: 40px;
  }
  
  .history-o{
    border: 1px solid white;
    background-color: #fdfdfd;
}



.heading-mission{
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.mission-page {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 20px;
padding-left: 20px;
padding-right: 20px;
}


.mission-heading {
 margin-top: -10px;
}


.mission-text {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}



.video-section {
    margin: 20px auto;
    max-width: 100%;
    border: 20px solid #ffffff; /* Border color */
    border-radius: 5px; /* Border radius for rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin-bottom: 30px;
  }

  .video-section-heads{
    padding-top: 5px;
    padding-bottom: 10px;
  }

  
  
  iframe {
    width: 100%;
    height: 450px;
  }
  
  button {
    display: block;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  /* Media Queries for responsiveness */
  @media (min-width: 596px) {
    .video-section {
      max-width: 540px;
    }
  }
  
  @media (min-width: 788px) {
    .video-section {
      max-width: 720px;
    }
  }
  
  @media (min-width: 1012px) {
    .video-section {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1220px) {
    .video-section {
      max-width: 1140px;
    }
  }
  
.faq-container {
    max-width: 800px;
    margin: 0 auto;

  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .faq-question {
    cursor: pointer;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0; /* Light border color */
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #e0e0e0;
  }
  
  .dropdown-icon {
    font-size: 10px;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
  
  .dropdown-icon.active {
    transform: rotate(90deg);
  }
  
  .faq-answer {
    color: #555;
    line-height: 1.5;
    margin-top: 10px;
  }
  
  h2 {
    color: #007bff;
  }
  
  h3 {
    font-size: 18px;
    color: #333;
  }
  
  .faq-item p{
    text-align: left;
  }
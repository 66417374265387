.history{
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column; 
  margin-left: 100px;
  margin-right: 100px;
}

.row-f {
  display: flex;
  justify-content: space-evenly; 
  margin-bottom: 40px;
}

.item {
  text-align: center;
  background-color: rgb(195, 193, 191);
  padding: 30px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; 
}

.item:hover img {
  transform: scale(1.1); 
}

.item:hover {
  background-color: rgb(160, 159, 158); 
}

.item img {
  width: 100px; 
  height: 100px;
  object-fit: cover; 
  transition: transform 0.3s ease-in-out;
}

.container-facilities{
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .row-f {
    flex-direction: column; 
    height: auto; 
    margin-bottom: 10px;
  }

  .item {
    margin-bottom: 10px;
  }

}
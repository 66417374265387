/* Carousel.css */
.carousel-container {
    width: 70%;
    overflow: hidden;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    height: 500px;
  }
  
  .carousel-image {
    width: 100%;
    height: auto; /* Set height to auto to maintain the image's aspect ratio */
    /* max-width: 100%; */
    display: block;
    margin: 0 auto;
    position: absolute;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }

  /* Add this at the end of your CSS file or in a separate mobile.css file */
@media screen and (max-width: 900px) {

    .carousel-container {
      width: 100%; 
    }
    .carousel-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
    .carousel {
      height: 300px; /* Adjust height for smaller screens */
    }
  
    .carousel-button {
      font-size: 18px; /* Adjust font size for smaller screens */
    }
  
  }
  
  
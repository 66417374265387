* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}


/* App.css */
.mainSection {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between; 
  /* min-height: 100vh; */
}


.home,
.services,
.products,
.sign-up
.aboutus {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #9c1a1a;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #681d1d;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #a31111;
  font-size: 100px;
}
/* Add this at the end of your CSS file or in a separate mobile.css file */
@media screen and (max-width: 900px) {
  .mainSection {
      display: block;
/* Change to block layout for mobile */
    }


    
    
}






.events-section {
    text-align: center;
    padding-bottom: 40px;
    background-color: #e8e2e2;
}

h2 {
    text-align: center;
    font-size: 2rem;
    padding: 40px;
    color: #333;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #e68040;
    text-underline-offset: 10px;
}

.events-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.event-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    padding: 20px;
    cursor: pointer;
}

.date {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.date h1 {
    font-size: 40px;
    margin: 0;
}

.date span {
    font-size: 18px;
    font-weight: 400;
}

.eventDetail{
    font-size: 1.2rem;
    margin-top:1%;
    font-weight: 600;
}

@media (max-width: 768px) {
    .events-container {
        flex-direction: column;
        align-items: center;
    }

    .event-card {
        width: 100%;
        max-width: 300px;
    }
}
/* Footer.css */

.app-footer {
 background-color: #8d817d;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-info {
  margin-top: 20px;
  text-align: center;
}
.footer-info-header {
  margin-top: 5px;
  text-align: center;
}

.map-container {
  flex: 1;
   width: 80% ;
   height: 800px;
}

.map-container iframe {
  width: 100%;
  height: 500px; 
  border: 0;
}

.Details-p{
  margin-bottom: 5px;
}

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-icons a {
  margin: 0 10px;
}

.social-icons img {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}

/* Add more styling as needed */
@media screen and (max-width: 960px) {
 


}
/* Add this CSS in your stylesheets or inline styles */

.headline-title {
    margin-top: 30px;
    font-size: 2em;
    font-weight: bold;
     text-align: center;
    color: #e68040;
    margin-bottom: 20px;
  }
  
  .admission-info {
    margin-bottom: 20px;
    text-align: center;
    color: black;
  }

  .admission-info h2{
    
  }
  
  
  .age-criteria {
    margin-left: 20px;
  }
  
  .age-criteria div {
    margin-bottom: 10px;
  }
  
  label {
    margin-left: 10px;
    color: #555;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .headline-2 ul {
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    margin-left: 130px;
  }

  .headline-2 li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .headline-2 li img {
    margin-right: 10px; /* Adjust the margin as needed */
  }



  .headline-3 {
    background-color: #fff; /* Light grey background color */
    padding: 50px;
    margin: 50px;
    border-radius: 8px;
  }
  
  .headline-3 h2 {
    color: #333;
    text-align: center;
    margin-bottom: 25px;
  }
  
  .headline-3-admission {
    margin-left: 20px;
    list-style-type: none;
  }

  .headline-3-admission-admission
  {
     margin-top: 15px;
     margin-bottom: 15px;
     list-style-type: decimal;
  }

  .headline-3-admission-admission li
  {
     margin-left: 45px;
  }

  .headline-3 li {
    margin-bottom: 10px;
  }
  
  .headline-3 p {
    color: #555;
    margin-bottom: 50px;

  }
  
  .headline-3 .note {
    color: #555;
    margin-left: 20px;
  }
  
  .headline-3 a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
  }
  
  .headline-3 a:hover {
    text-decoration: underline;
  }

.heading-3-documents {
    list-style-type: decimal;
    margin-left: 70px;
    margin-bottom: 25px;
}


@media screen and (max-width: 900px) {
  .headline-2 ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    margin-left: 0px;
    padding: 20px;
  }


  .headline-3 {
    padding: 10px;
  }
  
}



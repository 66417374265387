/* AmenitiesPage.css */

.amenities-container {
    display: flex;
    justify-content: space-around;
    padding: 5px;
  }
  
  .amenity {
    height: auto;
    width: 100%;
    margin: 10px;
    padding: 25px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .amenity h2 {
    text-align: center;
    color: #3769e0;
    margin-bottom: 10px;
  }
  
  .amenity p {
    color: #666;
  }



  @media screen and (max-width: 768px) {
    .amenities-container {
        flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }
  
    .amenity {
      max-width: 100%;
      margin-bottom: 20px;
      padding: 25px;
    }
  }
  
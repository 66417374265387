/* AdmissionsForm.css */
.admissions-form {
    width: 30%;
    padding: 20px;
    background-color: rgb(247, 230, 219); /* Set a green background color */
    color: #fff; /* Set text color to white */
    text-align: center;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 500px;
  }
  
  .admissions-form h2 {
    color: #0d0b0b; /* Set heading text color to white */
    margin-bottom: 15px;
  }
  
  .admissions-form p {
    color: #fff; /* Set paragraph text color to white */
  }
  
  .admissions-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    margin: 10px 0;
    width: 100%;
    text-align: left;
  }
  
  label {
    font-weight: bold;
    color: #fff; /* Set label text color to white */
  }
  
  input {
    margin-top: 5px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    background-color: #f7f3f3; /* Set button background color to white */
    color: #121612; /* Set button text color to green */
    padding: 10px;
    cursor: pointer;
    border: none;
    width: 50%;
    margin-top: 5px;
    border-radius: 4px;
  }

  .form-button{
    background-color: #141212;
  }

  @media screen and (max-width: 900px) {

  
    .admissions-form {
        width: 100%;
      }
      
      
  }
  
  